import React from 'react';
import { FaRegHandPointer } from 'react-icons/fa';
import { MdBookmarkBorder, MdPerson } from 'react-icons/md';
import { IoIosSettings, IoMdStarOutline } from 'react-icons/io';

import { HeaderItem, UserBadgeItem } from 'components/header';

import { NotificationsIcon, ProfileIcon, SettingsIcon } from 'assets';

import { ApiUser, isAdminUser, isRecruiterUser, isStudentUser } from './token';

// Public
export const NON_AUTH_HEADER_ITEMS: HeaderItem[] = [
	{
		title: 'Zjistit více - Zaměstnavatel',
		to: '/how-it-works/company',
	},
	{
		title: 'Zjistit více - Student',
		to: '/how-it-works/student',
	},
	{
		title: 'Zjistit více - Absolvent',
		to: '/how-it-works/absolvent',
	},
	{
		title: 'Obchodní podmínky',
		to: '/conditions',
	},
];

// Company
export const COMPANY_HEADER_ITEMS: HeaderItem[] = [
	{
		title: 'Moje nabídky',
		to: `/offers`,
	},
	{
		title: 'Moje akce',
		to: `/company-events`,
	},
	{
		title: 'Nabídky',
		to: `/search/offers`,
	},
	{
		title: 'Akce',
		to: `/search/company-events`,
	},
	{
		title: 'Zaměstnavatelé',
		to: `/search/companies`,
	},
	{
		title: 'Hledat studenty',
		to: `/search/students`,
	},
	{
		title: 'Moje objednávky',
		to: `/orders`,
	},
];

const COMPANY_BADGE_ITEMS: UserBadgeItem[] = [
	{
		title: 'Profil',
		icon: <ProfileIcon width="100%" height="100%" />,
		to: '/company/edit',
	},

	{
		title: 'Notifikace',
		icon: <NotificationsIcon width="100%" height="100%" />,
		to: '/notifications',
	},

	{
		title: 'Nastavení',
		icon: <SettingsIcon width="100%" height="100%" />,
		to: '/settings',
	},
];

// Student
export const STUDENT_HEADER_ITEMS: HeaderItem[] = [
	{
		title: 'Nabídky',
		to: `/search/offers`,
	},
	{
		title: 'Akce',
		to: `/search/company-events`,
	},
	{
		title: 'Zaměstnavatelé',
		to: `/search/companies`,
	},
	{
		title: 'Studenti studentům',
		to: `/students-to-students`,
	},
];

const STUDENT_BADGE_ITEMS: UserBadgeItem[] = [
	{
		title: 'Profil',
		icon: <MdPerson width="100%" height="100%" />,
		to: '/student',
	},
	{
		title: 'Moje reakce',
		icon: <FaRegHandPointer width="100%" height="100%" />,
		to: '/my-reactions',
	},
	{
		title: 'Uložené nabídky',
		icon: <MdBookmarkBorder width="100%" height="100%" />,
		to: '/my-offers',
	},
	{
		title: 'Oblíbení zaměstnavatelé',
		icon: <IoMdStarOutline width="100%" height="100%" />,
		to: '/my-companies',
	},
	{
		title: 'Nastavení',
		icon: <IoIosSettings width="100%" height="100%" />,
		to: '/settings',
	},
];

const ADMIN_BADGE_ITEMS: UserBadgeItem[] = [
	{
		title: 'Nastavení',
		icon: <SettingsIcon width="100%" height="100%" />,
		to: '/settings',
	},
];

// Admin
export const ADMIN_HEADER_ITEMS: HeaderItem[] = [
	{
		title: 'Pracovní nabídky',
		to: `/search/offers`,
	},
	{
		title: 'Statistiky',
		to: `/statistics`,
	},
];

// Super Admin
export const SUPER_ADMIN_HEADER_ITEMS: HeaderItem[] = [
	{
		title: 'Produkty',
		to: `/products`,
	},
	{
		title: 'Zaměstnavatelé',
		to: `/search/companies`,
	},
	{
		title: 'Objednávky',
		to: `/orders`,
	},
	{
		title: 'Studenti',
		to: `/search/students`,
	},
	{
		title: 'Firemní akce',
		to: `/search/company-events`,
	},
];

// Get header items based on user authentication
export const getUserHeaderItems = (user?: ApiUser) => {
	if (isAdminUser(user)) {
		// Admin header items
		return {
			items: [...ADMIN_HEADER_ITEMS, ...SUPER_ADMIN_HEADER_ITEMS],
			badgeItems: ADMIN_BADGE_ITEMS,
			collapseWidth: '1280px',
		};
	} else if (isStudentUser(user)) {
		// Student header items
		return {
			items: STUDENT_HEADER_ITEMS,
			badgeItems: STUDENT_BADGE_ITEMS,
			collapseWidth: '1280px',
		};
	} else if (isRecruiterUser(user)) {
		// Company header items
		return {
			items: COMPANY_HEADER_ITEMS,
			badgeItems: COMPANY_BADGE_ITEMS,
			collapseWidth: '1280px',
		};
	}

	// Non auth
	return {
		items: NON_AUTH_HEADER_ITEMS,
		badgeItems: [],
		collapseWidth: '850px',
	};
};
