/** @jsxImportSource @emotion/core */
import React from 'react';
import ReactQuill from 'react-quill';

import { sanitizeRichText } from './RichTextInput';
import { QuillContentStyles } from './_styles';

type Props = {
	value?: string;
};

const RTText: React.FC<Props> = ({ value = '' }) => (
	<ReactQuill
		className="notranslate"
		theme=""
		value={sanitizeRichText(value)}
		readOnly
		modules={{
			toolbar: [],
			clipboard: {
				matchVisual: false,
			},
		}}
		css={QuillContentStyles}
	/>
);

export default RTText;
